

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  effect,
  EventEmitter,
  inject,
  input,
  Input,
  InputSignal,
  OnDestroy,
  OnInit,
  output,
  Output,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { ApiService } from "@services/api.service";
import { environment } from "../../../../environments/environment";
import { animate, state, style, transition, trigger } from "@angular/animations";
import { find, get, set, clone, uniqBy, uniqWith } from "lodash";
import { SharedData } from "@services/sharedData.service";
import { SocketService } from "@services/socket.service";
import { StoreService } from "@services/store.service";
import { HelpFunctions } from "@helper/help-functions";
import { Subject, Subscription } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { SchemaClass } from "./schema/schema_class";
import { FormioComponent, FormioModule } from "@formio/angular";
import { takeUntil } from "rxjs/operators";
import { JsonPipe, KeyValuePipe, NgClass } from "@angular/common";
import { provideAnimations } from "@angular/platform-browser/animations";
import { JsonParsePipe } from "@worker/pipe/jsonParse.pipe";
import { comp } from "./schema/spicial_rewardList";
import { InternalMessageService } from "@services/internalMessage.service";
import { FormioHandlerService } from "@services/formio-handler.service";
import { NgZone } from '@angular/core';
@Component({
  selector: "app-schema-renderer",
  templateUrl: "./schema-renderer.component.html",
  styleUrls: ["./schema-renderer.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideAnimations(), FormioHandlerService],
  animations: [
    trigger("deployCont", [
      state("start", style({ width: 0 + "px" })),
      state("end", style({ width: 600 + "px", margin: 20 + "px" })),
      transition("start <=> end", animate(450)),
    ]),
  ],
  imports: [KeyValuePipe, NgClass, FormioModule, JsonPipe],
  standalone: true,
})
export class SchemaRendererComponent implements OnInit, OnDestroy, AfterViewInit {
  FormioHandlerService: FormioHandlerService = inject(FormioHandlerService);

  readonly fullResponce: InputSignal<any> = input({});
  DataCreate = output<any>({});
  @ViewChild(FormioComponent) formioComponent: FormioComponent | any;
  public successEmitter: EventEmitter<any> = new EventEmitter();
  public succesMessage: Date | any;
  public data: any;
  public onDestroy$ = new Subject<boolean>();

  ApiService: ApiService = inject(ApiService);
  SharedData: SharedData = inject(SharedData);
  SocketService: SocketService = inject(SocketService);
  StoreService: StoreService = inject(StoreService);
  HelpFunctions: HelpFunctions = inject(HelpFunctions);
  route: ActivatedRoute = inject(ActivatedRoute);
  cdref: ChangeDetectorRef = inject(ChangeDetectorRef);
  InternalMessageService: InternalMessageService = inject(InternalMessageService)
  NgZone: NgZone = inject(NgZone)

  public SettingFormValidFlag: any;
  public subMitStop: any;
  public triggerRefresh = new EventEmitter();
  public deployFlag: boolean = false;
  public languageFormObj: any = { selected: "", langData: {} };
  public form: Object = {
    components: [],
  };

  public deployedFlag: boolean = false;
  public tabSelected: number = 0;
  public deployContState: string = "start";
  public formio: any;
  public dump = { components: [] }

  ngOnInit(): void {

    // this.test()

    if (this.fullResponce()?.lang.length === 0) {
      this.fullResponce().lang.push({ text: "-", value: "-" });
    }
    if (!this.languageFormObj.selected) {
      this.languageFormObj.selected = this.fullResponce().lang[0].value;
    }
    this.languageFormObj.langData = this.setInitialData()
    this.StoreService.addToStore("cmsLanguage", this.languageFormObj.selected);
    this.SharedData.saveFormEmitter.pipe(takeUntil(this.onDestroy$)).subscribe((response) => {
      this.formio.submit().then((submission: any) => {
        console.log();
      });
    });
  }



  setInitialData() {
    const data: any = {}
    for (let item of this.fullResponce()?.lang) {
      const template = new SchemaClass()
      data[item.value] = template.template;
      data[item.value].schema = this.setSchema(item.value, this.fullResponce());
      if (this.fullResponce().response._type == "reward_list") {
        if (this.fullResponce().responseData?.data[item.value]) {
          this.fullResponce().responseData.data[item.value] = this.removeDuplicates(this.fullResponce().responseData.data[item.value])
        }
      }

      if (this.fullResponce().responseData?.data) {
        data[item.value].submissionData.data = item.value === "-" ? this.fullResponce().responseData.data : this.assignData(item);
      }
    }
    return data
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
    if (!this.fullResponce()?.allreadyOpened && this.fullResponce()?.dataControlFlow) {
      this.SocketService.socket_emit(this.fullResponce().edited_document, "close_update_doc");
    }
  }

  assignData(item: any) {
    if (this.fullResponce().responseData.data[item.value]) {
      const en = JSON.parse(JSON.stringify(this.fullResponce().responseData.data["en"]))
      return Object.assign(en, this.fullResponce().responseData.data[item.value])
    }
    return Object.assign({}, this.fullResponce().responseData.data["en"])
  }

  ngAfterViewInit() {
    this.formioComponent?.formioReady.then((formio: any) => {
      this.formio = formio;

    });
  }

  setSchema(lang: any, data: any) {
    const project = this.StoreService.getFromStore_Static("DD_projects").find((el: any) => el.alias == this.fullResponce().project);
    const schema = this.fullResponce()?.allreadyOpened ? this.disableSchema(data.response.schema) : JSON.parse(JSON.stringify(data.response.schema));
    if (lang !== "en" && lang !== "-") {
      schema["components"].forEach((element: any) => {
        this.elementDisable(element);
      });
    } else {
      // schema["components"].forEach((element: any) => {
      //   this.elementActivate(element);
      // });
    }

    schema["components"] = this.HelpFunctions.restoreLinks(
      schema["components"],
      this.fullResponce().project,
      lang,
      this.fullResponce().response._type,
      this.fullResponce().currentEnvirentment,
      project?.host_id
    );
    return schema;
  }

  selectLangTab(event: any) {
    this.languageFormObj.selected = event;
    if (this.fullResponce().response._type == "reward_list" && Object.keys(this.languageFormObj.langData[event].submissionData.data).length) {
      this.languageFormObj.langData[event].submissionData.data = this.removeDuplicates(this.languageFormObj.langData[event].submissionData.data)
    }
    const data = this.languageFormObj.langData[event]
    this.NgZone.runOutsideAngular(() => {
      Promise.resolve().then(() => {
        this.triggerRefresh.emit({
          property: data.schema,
          submission: {
            data: data.submissionData.data,
          },
        });
      });
    });
    // setTimeout(() => {
    //   this.triggerRefresh.emit({
    //     property: data.schema,
    //     submission: {
    //       data: data.submissionData.data,
    //     },
    //   });
    // }, 1);
  }

  customEvent(event: any) {
    if (event.type == "generate" && this.fullResponce().response._type == "freespins_bet" && event.data?.provider?.alias) {
      this.ApiService.Getmethod(this.HelpFunctions.restoreLink("content/deploy/select/rollingslots/game?fields=id,{dl}.provider,{dl}.`system`,{dl}.game_id,{dl}.name&system=" + event.data.provider.systems[0].alias + "&provider=" + event.data.provider.alias + "&sort={dl}.name&filterJson={\"{dt}.allow_freespins\": true}")).subscribe((response: any) => {
        this.languageFormObj.langData[this.languageFormObj.selected].submissionData.data
        const examp = event.data.game_bets.pop()
        event.data.game_bets = response.map((el: any) => {
          examp.info.game_id = el.game_id
          examp.info.game_name = el.name
          return JSON.parse(JSON.stringify(examp))
        })
        this.triggerRefresh.emit({
          property: this.languageFormObj.langData[this.languageFormObj.selected].schema,
          submission: {
            data: this.languageFormObj.langData[this.languageFormObj.selected].submissionData.data,
          },
        });
      })
    }
  }

  OnChange(event: any) {
    if (typeof event.data !== "undefined") {
      this.SettingFormValidFlag = event;

      if (this.fullResponce().response._type == "reward_list") {
        if (event.changed && event.changed.component.key == "prizes") {
          this.indexSQL(event.data.prizes)

          event.changed.instance.triggerRedraw()
        }
      }

    }
  }

  deployStateChange() {
    this.deployContState = this.deployContState === "end" ? "start" : "end";
  }

  returnZero() {
    return 0;
  }
  sendSchema(): void { }

  elementDisable(element: any) {
    //Check that element has a component
    if (typeof element.components !== "undefined") {
      //Make a recursion if element has a component
      if (typeof element.copyTrans !== "undefined" && !element.copyTrans) {
        element.components = JSON.parse(JSON.stringify(element.components).replace(/"disabled":\w+/gmi, '"disabled":true'))
        return element
      }
      element.components.forEach((element: any) => {
        return this.elementDisable(element);
      });
    } else if (typeof element.columns !== "undefined") {
      if (typeof element.copyTrans !== "undefined" && !element.copyTrans) {
        element.columns = JSON.parse(JSON.stringify(element.columns).replace(/"disabled":\w+/gmi, '"disabled":true'))
        // console.log(element) 
        return element
      }
      element.columns.forEach((element: any) => {
        return this.elementDisable(element);
      });
    } else {
      //Disable element if it has a copyTrans(single Language) flag
      if (typeof element.copyTrans !== "undefined" && !element.copyTrans) {
        element.disabled = true;
        //Atrributes can be setup in builder for any component
        if (Object.keys(element.attributes).length) {
          for (let item in element.attributes) {
            if (item.includes("copyTrans") && item.includes(this.fullResponce().project) && element.attributes[item] == 'false') {
              element.disabled = false;
            }
          }
        }
      }
    }
    return element;
  }

  //FOR projects that require activate certain field
  elementActivate(element: any) {
    //Check that element has a component
    if (typeof element.components !== "undefined") {
      element.components.forEach((element: any) => {
        return this.elementActivate(element);
      });
    } else if (typeof element.columns !== "undefined") {
      element.columns.forEach((element: any) => {
        return this.elementActivate(element);
      });
    } else {
      //Disable element if it has a copyTrans(single Language) flag
      if (typeof element.copyTrans !== "undefined" && !element.copyTrans) {
        //Atrributes can be setup in builder for any component
        if (Object.keys(element.attributes).length) {
          for (let item in element.attributes) {
            if (item.includes("copyTrans") && item.includes(this.fullResponce().project) && element.attributes[item] == 'false') {
              console.log(element)
              element.copyTrans = !element.copyTrans
            }
          }
        }
      }
    }
    return element;
  }
  errors(event: any) {
    for (const iterator of event) {
      this.InternalMessageService.errorMessage(iterator.message);
    }
  }

  ready(event: any) {
    this.subMitStop = event.formio;
    this.formio = event.formio;
    this.formio.events.on('ikbs_copy', (data: any) => {
      console.log(data)
      this.languageFormObj.langData[this.languageFormObj.selected].submissionData.data[data.component.key].splice(data.index + 1, 0, JSON.parse(JSON.stringify(data.rowData)))
      this.indexSQL(this.languageFormObj.langData[this.languageFormObj.selected].submissionData.data[data.component.key])
      this.triggerRefresh.emit({
        property: this.languageFormObj.langData[this.languageFormObj.selected].schema,
        submission: {
          data: this.languageFormObj.langData[this.languageFormObj.selected].submissionData.data,
        },
      });
    });
    this.cdref.detectChanges();
  }

  onSubmitTheForm(event: any, tabLang: any) {
    this.subMitStop.emit("submitDone");
    //If EN was submitted needs to go through all ovet tabs and copy data for single language tab
    var re = new RegExp(environment.host, "gi");
    // this.languageFormObj.langData = JSON.parse(JSON.stringify(this.languageFormObj.langData).replace(re, ""));
    const submittedData = JSON.parse(JSON.stringify(event.data).replace(re, "").replace(/"url":"api\/en/gmi, '"url":"/api/en'))
    // event = JSON.parse(JSON.stringify(event).replace(re, ""));
    if (this.fullResponce().response._type == "reward_list") {
      this.multiply(submittedData)
    }

    if (tabLang === "en") {
      //@ts-ignore
      const hugeData: any = this.HelpFunctions.getAllLKeys(this.languageFormObj.langData[tabLang].schema, ["key"]);
      //@ts-ignore
      for (let compon of hugeData["key"]) {
        for (let link in compon.linkData) {
          let redacLink = HelpFunctions.getLinksKeysArrayData(submittedData, compon.linkData[link]);
          for (let finishLink in redacLink) {
            let keyValue = get(submittedData, redacLink[finishLink]);
            if (Array.isArray(keyValue) && keyValue.length == 1 && typeof keyValue[0] == "string" && keyValue[0] == "") {
              keyValue = [];
              set(submittedData, redacLink[finishLink], keyValue);
            }
          }
        }
      }
      this.languageFormObj.langData[tabLang]["submissionData"]["data"] = submittedData;

      for (let item of Object.keys(this.languageFormObj.langData)) {
        //Skiping EN
        if (item === "en") continue;

        const _submissionData = this.languageFormObj.langData[item]["submissionData"]
        _submissionData["data"] = _submissionData["data"] ? _submissionData["data"] : {}

        for (let compon of hugeData["key"]) {
          if (compon.copyTrans) {
            continue;
          }

          for (let link in compon.linkData) {
            let redacLink = HelpFunctions.getLinksKeysArrayData(
              this.languageFormObj.langData["en"]["submissionData"]["data"],
              compon.linkData[link]
            );

            for (let finishLink in redacLink) {
              let keyValue = get(submittedData, redacLink[finishLink]);
              if (this.schemaEmptyFields(keyValue)) {
                keyValue = [];
              }
              set(
                _submissionData["data"],
                redacLink[finishLink],
                keyValue
              );
            }
          }
        }
      }
    } else {
      this.languageFormObj.langData[tabLang]["submissionData"]["data"] = submittedData;
    }
    const structedData = this.dataUpdateToSend(this.languageFormObj.langData);

    if (!this.SettingFormValidFlag.isValid) {
      this.InternalMessageService.errorMessage("Schema Settings is not valid");
    }
    this.DataCreate.emit({
      data: structedData,
      trailer: this.fullResponce(),
      dataControlFlow: this.fullResponce().dataControlFlow,
    });
  }

  multiply(data: any) {
    let result: any = []
    data.prizes.forEach((el: any, index: number) => {
      for (let i = el.start_index; i <= el.end_index; i++) {
        el = Object.assign({}, el)
        el.index = i
        result.push(el)
      }
    })
    data.prizes = result
  }

  removeDuplicates(event: any) {
    if (!event.prizes[0].start_index) {
      return event
    } else {
      if (event.prizes.length) {
        event.prizes = uniqBy(event.prizes, 'start_index')
      }
    }
    return event
  }

  schemaEmptyFields(keyValue: any) {
    if (Array.isArray(keyValue) && keyValue.length == 1) {
      if (typeof keyValue[0] == "string" && keyValue[0] == "") {
        return true
      }
      if (keyValue[0] === null || (typeof keyValue[0] == "object" && Object.keys(keyValue[0]).length == 0)) {
        return true
      }
    }
    return false
  }

  dataUpdateToSend(data: any) {
    let result: any = {};
    for (let item in data) {
      const _data = data[item].submissionData.data
      if (typeof _data !== "undefined") {
        delete _data["submit"];
        delete _data["hiddenFlag"];
      }
      if (this.fullResponce().responseData && item !== "-") {
        result[item] = _data;
      } else {
        if (Object.keys(_data).length > 0 && item !== "-") {
          result[item] = _data;
        }
        if (Object.keys(_data).length > 0 && item == "-") {
          result = _data;
        }
      }
    }
    return result;
  }

  disableSchema(schema: any) {
    let result = JSON.stringify(schema);
    result = result.replace(/\"disabled\"\:false/gim, '"disabled": true');
    return JSON.parse(result);
  }

  indexSQL(data: any) {
    let first = 0;
    let second = 0;
    data.forEach((el: any, index: number) => {
      if (index == 0) {
        el.start_index = 1
        first = el.start_index
        if (!el.end_index) {
          el.end_index = index + 1;
          second = el.end_index
        } else {
          first = el.start_index
          second = el.end_index
        }
      }
      if (index !== 0) {
        if (el.start_index >= second) {
          if (second + 1 < el.start_index) {
            el.start_index = second + 1
          }
          if (second == el.start_index) {
            el.start_index = second + 1
          }
          if (el.start_index > el.end_index) {
            el.end_index = el.start_index
          }
          first = el.start_index
          second = el.end_index
        } else {
          first = second + 1
          if (el.end_index > second) {
            second = el.end_index
          } else {
            second = second + 1
          }
        }
        el.start_index = first;
        el.end_index = second;
      }
    });
  }


  render() { }
}
