.coverInTrash {
    background: white;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
    opacity: 0.3;
    left: 0px;
}

.disabledLangs {
    position: absolute;
    height: 40px;
    width: 63%;
    background: rgb(255, 255, 255, 0.7);
    left: 0px;
}

.deploy {
    position: absolute;
    right: 161px;
    top: -3px;
    z-index: 3;
    display: flex;

    .deployHisotry {
        color: #ffc247;
        font-size: 30px;
        margin-left: 10px;
    }
}

.cont {
    display: flex;
    flex-direction: row;
}

.tabCont {
    flex: 90%;
    min-width: 700px;
    position: relative;
    overflow: auto;

    formio-alerts {
        display: none;
    }

    .has-success {
        display: none;
    }

    .k-content {
        overflow-y: hidden;
    }

    .formio-component {
        .form-control {
            min-width: 70px;
        }
    }

    .formio-component-file {
        background: aliceblue;
    }

    .table-bordered th,
    .table-bordered td {
        min-width: 185px;
    }

    .open-modal-button {
        display: flex;
        flex-wrap: wrap;
        row-gap: 7px;
        column-gap: 2px;

    }

    .langNamesCont {
        display: flex;

        .langItem {
            padding: 6px 12px;
            color: inherit;
            display: inline-flex;
            align-items: center;
            cursor: pointer;
        }

        .selectedLang {
            color: #0771ED !important;
            border-bottom: 2px solid #0771ED !important;

        }
    }


}
formio {
    .formio-form-group {
        margin: 2px;
    }
}

.formio-component-hiddenFlag {
    height: 50px;
}


.pay_copy {

    button{
        margin-top: 34px;
    }
    @media screen and (max-width: 767px) {
        button{
            margin-top: 10px;
            width: fit-content;
        }
    }
}

.freespin_bets_columns{
    flex-wrap: nowrap;
    width: 100%;
    overflow-x: scroll; 
    >div{
        width: 20%;   
    }
}